<template>
    <div class="grid" @keyup="handleKeyUp" :class="`${loadingRemove && 'pointer-events-none'}`">
        <Menu ref="menu" :model="actionItems" :popup="true" />
        <Dialog v-model:visible="showTimeLine" :style="{ width: '70vw' }">
            <template #header>
                <h3>{{ handleFormatDate(selectedPlanAction.createdAt) }} - {{ selectedPlanAction.nomeAcao }}</h3>
            </template>
            <iTimeLine :selected-id="selectedPlanAction.id" />
            <template #footer>
                <Button label="Voltar" icon="pi pi-arrow-left" @click="showTimeLine = false" class="p-button-outlined mr-4 b-rounded" />
            </template>
        </Dialog>
        <Dialog v-model:visible="showRemovePlan" class="p-dialog-no-header">
            <template #header>
                <div></div>
            </template>
            <div class="remove-dialog p-5 pt-0">
                <p>Você tem certeza que deseja remover este Plano de ação?</p>
            </div>

            <template #footer>
                <Button
                    label="Voltar"
                    icon="pi pi-arrow-left"
                    @click="showRemovePlan = false"
                    class="p-button-outlined p-button-danger mr-4 b-rounded"
                />
                <Button
                    :loading="loadingRemove"
                    label="Remover"
                    icon="pi pi-cross"
                    @click="
                        () => {
                            if (this.selectedPlanAction.status === 'EM_ANDAMENTO' || this.selectedPlanAction.status === 'CONCLUIDO') {
                                this.showRemovePlan = false;
                                this.showJustifyRemovePlan = true;
                            } else {
                                this.removePlanosDeAcao();
                            }
                        }
                    "
                    class="p-button-danger mr-4 b-rounded"
                />
            </template>
        </Dialog>

        <Dialog v-model:visible="showJustifyRemovePlan" class="p-dialog-no-header">
            <template #header>
                <div></div>
            </template>
            <div class="remove-dialog p-5 pt-0">
                <p>Justifique a exclusão desse plano</p>
            </div>

            <Textarea class="w-full" v-model="removeJustify" rows="4" placeholder="Justificar" />
            <small v-if="submitted && !removeJustify" class="p-error">Justificar obrigatório.</small>
            <template #footer>
                <Button
                    label="Voltar"
                    icon="pi pi-arrow-left"
                    @click="showJustifyRemovePlan = false"
                    class="p-button-outlined p-button-danger mr-4 b-rounded"
                />
                <Button
                    :loading="loadingRemove"
                    label="Remover"
                    icon="pi pi-cross"
                    @click="removePlanosDeAcao"
                    type="submit"
                    class="p-button-danger mr-4 b-rounded"
                />
            </template>
        </Dialog>

        <Dialog v-model:visible="abrirModalMaisFiltros" :style="{ width: '701px' }">
            <template #header>
                <h3>Filtros</h3>
            </template>
            <div class="grid">
                <div class="col-8 flex align-items-center gap-5 mt-2">
                    <div class="flex align-items-start">
                        <RadioButton v-model="filters.dateType" name="da" id="da" value="INICIAL" />
                        <label for="da" class="ml-2">Data inicial</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="filters.dateType" name="dpc" id="dpc" value="PREVISTA" />
                        <label for="dpc" class="ml-2">Data prevista de conclusão</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="filters.dateType" name="dc" id="dc" value="CONCLUSAO" />
                        <label for="dc" class="ml-2">Data de conclusão</label>
                    </div>
                </div>
                <div class="col-4">
                    <label for="date">Periodo</label>
                    <Calendar
                        placeholder="Selecione"
                        selectionMode="range"
                        class="mt-2 input-date"
                        id="date"
                        v-model="filters.date"
                        showIcon
                        style="width: 100%"
                    />
                </div>
                <div class="col-6 flex align-items-center gap-5 mt-2">
                    <div class="flex align-items-start">
                        <RadioButton v-model="filters.involvedType" name="user" id="user" value="USUARIO" />
                        <label for="user" class="ml-2">Usuário</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="filters.involvedType" name="employee" id="employee" value="FUNCIONARIO" />
                        <label for="employee" class="ml-2">Funcionário</label>
                    </div>
                </div>
                <div class="col-6" :key="filters.involvedType">
                    <label>Pessoa envolvida</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.involved"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="filters.involvedType === 'FUNCIONARIO' ? funcionarios : usuarios"
                        optionLabel="name"
                        optionValue="name"
                        filter
                    />
                </div>
                <div class="col-3">
                    <label>Grupo de risco</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.riskGroup"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="grupos"
                        optionLabel="nome"
                        optionValue="valor"
                    />
                </div>
                <div class="col-3">
                    <label>Perigo/Fator de risco</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.riskFactor"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="riscos"
                        optionLabel="nome"
                        optionValue="id"
                        filter
                    />
                </div>
                <div class="col-3">
                    <label>Descrição do risco</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.riskDescription"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="
                            descRiscos.map((element) => {
                                const { riscoDescricaoRisco, ...rest } = element;

                                return rest;
                            })
                        "
                        optionLabel="nome"
                        optionValue="id"
                        filter
                    />
                </div>
                <div class="col-3">
                    <label>Objetivo</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.objective"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="objectiveActionOptions"
                        optionLabel="label"
                        optionValue="value"
                    />
                </div>
                <div class="col-3">
                    <label>Prioridade</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.priority"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="prioridadeOpcoes"
                        optionLabel="label"
                        optionValue="value"
                    />
                </div>
                <div class="col-3">
                    <label>EPI</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.epi"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="epis"
                        optionLabel="nome"
                        optionValue="id"
                        filter
                    />
                </div>
                <div class="col-3">
                    <label>EPC</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.epc"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="epcs"
                        optionLabel="nome"
                        optionValue="id"
                        filter
                    />
                </div>
                <div class="col-3">
                    <label>Resultado</label>
                    <Dropdown
                        class="mt-2"
                        v-model="filters.result"
                        style="width: 100%"
                        placeholder="Selecione"
                        :options="resultados"
                        optionLabel="label"
                        optionValue="value"
                        filter
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Cancelar" @click="abrirModalMaisFiltros = false" class="p-button-outlined mr-4 b-rounded" />
                <Button
                    label="Aplicar filtros"
                    @click="
                        () => {
                            filterList();
                            abrirModalMaisFiltros = false;
                        }
                    "
                    class="b-rounded"
                />
            </template>
        </Dialog>
        <div class="col-12" @keyup="handleKeyUp">
            <div class="card" @keyup="handleKeyUp">
                <header class="flex justify-content-between gap-8 align-items-center px-6 pt-6">
                    <div>
                        <div class="breadcrumb-container flex mb-4 gap-4">
                            <span class="pi pi-home"></span>
                            <span class="pi pi-angle-right"></span>
                            <p class="text-xl">SESMT</p>
                        </div>
                        <div>
                            <h4>Plano de Ação</h4>
                        </div>
                    </div>
                    <Button @click="$router.push('/plano-acao/criar')"><span class="pi pi-plus"></span>&nbsp;&nbsp;Nova ação</Button>
                </header>
                <div class="px-6 my-4">
                    <Divider />
                </div>
                <div class="grid px-6 py-4">
                    <div class="col">
                        <span class="p-input-icon-right" style="width: 100%">
                            <InputText v-model="filters.search" style="width: 100%" placeholder="Pesquisar" @keyup="handleKeyUp" />
                            <i class="pi pi-search pr-2" />
                        </span>
                    </div>
                    <div class="col">
                        <Dropdown
                            class="dropdown"
                            v-model="filters.status"
                            style="width: 100%"
                            placeholder="Status"
                            :options="status"
                            optionLabel="label"
                            optionValue="value"
                            showClear
                        />
                    </div>
                    <div class="col">
                        <Dropdown
                            class="dropdown"
                            v-model="filters.customer"
                            style="width: 100%"
                            placeholder="Cliente"
                            :options="customers"
                            optionLabel="name"
                            optionValue="id"
                            showClear
                            filter
                        />
                    </div>
                    <div class="col">
                        <Dropdown
                            class="dropdown"
                            v-model="filters.branch"
                            style="width: 100%"
                            placeholder="Unidade"
                            :options="branches"
                            optionLabel="name"
                            optionValue="id"
                            :disabled="!this.filters.customer"
                            showClear
                            filter
                        />
                    </div>
                    <div class="flex col">
                        <div v-if="removeFilters" class="mt-2 ml-auto md:mt-0">
                            <Button class="p-button p-button-text mr-2" @click="removerFiltros"
                                ><span class="pi pi-filter-slash"></span>&nbsp;&nbsp;Remover filtros</Button
                            >
                        </div>
                        <div class="mt-2 ml-auto md:mt-0">
                            <Button ref="filterButton" @click="filterList"><span class="pi pi-filter"></span>&nbsp;&nbsp;Filtrar</Button>
                        </div>
                    </div>
                </div>

                <div class="grid px-6 py-4">
                    <div class="flex col">
                        <Button class="p-button p-button-text mr-2" @click="abrirModalMaisFiltros = true"
                            ><span class="pi pi-plus"></span>&nbsp;&nbsp;Mais filtros</Button
                        >
                    </div>
                </div>

                <Toast />

                <div class="col-12 p-fluid painel painel-2 px-5">
                    <DataTable
                        dataKey="id"
                        :value="paginatedList"
                        :row-hover="true"
                        responsiveLayout="scroll"
                        breakpoint="640px"
                        :rows="10"
                        style="cursor: pointer"
                        selectionMode="single"
                    >
                        <template #empty> Nenhum registro encontrado. </template>

                        <template #loading> Carregando registros. Aguarde ... </template>
                        <Column field="status" header="Status" :sortable="true" sortField="status">
                            <template #body="slotProps">
                                <label :class="`${slotProps.data?.status}`">
                                    {{ slotProps.data?.status.replace('_', ' ') || '' }}
                                </label>
                            </template>
                        </Column>
                        <Column field="prioridadeAcao" header="Prioridade" :sortable="true" sortField="prioridadeCausa">
                            <template #body="slotProps">
                                <label :class="`${slotProps.data?.prioridadeCausa}`">
                                    {{ slotProps.data?.prioridadeCausa || 'Não definida' }}
                                </label>
                            </template>
                        </Column>
                        <Column field="dataInicial" header="Prazo" :sortable="true" sortField="dataInicial">
                            <template #body="slotProps">
                                <label>
                                    {{
                                        handleDate(slotProps.data?.dataInicial) +
                                        ' - ' +
                                        `${
                                            slotProps.data.dataConclusao
                                                ? handleDate(slotProps.data?.dataConclusao)
                                                : slotProps.data.dataPrevisao && slotProps.data.objetivoAcao !== 'CONTROLAR'
                                                ? handleDate(slotProps.data?.dataPrevisao)
                                                : slotProps.data.dataPrevisao && slotProps.data.objetivoAcao === 'CONTROLAR'
                                                ? 'em andamento'
                                                : 'em planejamento'
                                        }`
                                    }}
                                </label>
                            </template>
                        </Column>
                        <Column field="customerId" header="Cliente" :sortable="true" sortField="customerId">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data?.customer.name }}
                                </label>
                            </template>
                        </Column>
                        <Column field="unidadeId" header="Unidade" :sortable="true" sortField="unidadeId">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data?.unidade.name }}
                                </label>
                            </template>
                        </Column>
                        <Column field="nomeAcao" header="Nome da ação" :sortable="true" sortField="nomeAcao">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data?.nomeAcao }}
                                </label>
                            </template>
                        </Column>
                        <Column field="objetivoAcao" header="Objetivo" :sortable="true" sortField="objetivoAcao">
                            <template #body="slotProps">
                                <label>
                                    {{ `${slotProps.data?.objetivoAcao[0]}${slotProps.data?.objetivoAcao.substring(1).toLowerCase()}` }}
                                </label>
                            </template>
                        </Column>
                        <Column field="responsavel" header="Responsavél" :sortable="true" sortField="responsavel">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data?.envolvidos?.find((element) => element.responsavel)?.nomeEnvolvido ?? 'Não definido' }}
                                </label>
                            </template>
                        </Column>
                        <Column field="resultado" header="Resultado" :sortable="true" sortField="resultado">
                            <template #body="slotProps">
                                <label>
                                    {{
                                        slotProps.data?.resultado === 'EFICAZ'
                                            ? 'Eficaz'
                                            : slotProps.data?.resultado === 'NAO_EFICAZ'
                                            ? 'Não Eficaz'
                                            : 'Não definido'
                                    }}
                                </label>
                            </template>
                        </Column>

                        <Column bodyClass="text-right" headerStyle="width: 10rem;"> </Column>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button
                                    icon="pi pi-ellipsis-v p-button-secondary"
                                    class="p-button-text p-button-secondary"
                                    @click="toggleMenu($event, slotProps.data)"
                                />
                            </template>
                        </Column>
                    </DataTable>
                    <Paginator
                        class="pt-4"
                        :rows="perPage"
                        :totalRecords="total"
                        @page="onPage($event)"
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 50, 100]"
                        currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
                    >
                    </Paginator>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlanoAcaoService from '../../services/planoAcaoService';
import PlanoAcaoCausaService from '../../services/planoAcaoCausaService';
import iTimeLine from './components/linha-do-tempo.vue';
import moment from 'moment-timezone';
import BaseService from '../../services/BaseService';
import * as store from '../../services/store';

export default {
    components: {
        iTimeLine
    },
    data() {
        return {
            selectedCompany: null,
            list: [],
            paginatedList: [],
            actionItems: [
                {
                    label: 'Linha do tempo',
                    icon: 'pi pi-eye',
                    disabled: () => !this.$checkPermission('gestaosesmt_cadastros_plano_acao_linha_do_tempo:listar'),
                    command: () => this.goToTimeLime()
                },
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    disabled: () =>
                        !this.$checkPermission('gestaosesmt_cadastros_plano_acao:editar') || this.selectedPlanAction.status === 'EXCLUIDO',
                    command: () => this.goToEdit()
                },
                {
                    label: 'Remover',
                    icon: 'pi pi-trash',
                    disabled: () =>
                        !this.$checkPermission('gestaosesmt_cadastros_plano_acao:excluir') || this.selectedPlanAction.status === 'EXCLUIDO',
                    command: () => (this.showRemovePlan = true)
                }
            ],
            showTimeLine: false,
            showRemovePlan: false,
            showJustifyRemovePlan: false,
            removeJustify: '',
            selectedPlanAction: {},
            filtered: false,
            abrirModalMaisFiltros: false,
            selectedCustomer: [],
            loadingRemove: false,
            grupos: [
                { nome: 'Físico', valor: 1 },
                { nome: 'Químico', valor: 2 },
                { nome: 'Biológico', valor: 3 },
                { nome: 'Ergonômico', valor: 4 },
                { nome: 'Acidente Mecânico', valor: 5 },
                { nome: 'Outro', valor: 6 }
            ],
            objectiveActionOptions: [
                {
                    value: 'REDUZIR',
                    label: 'Reduzir'
                },
                {
                    value: 'ELIMINAR',
                    label: 'Eliminar'
                },
                {
                    value: 'CONTROLAR',
                    label: 'Controlar'
                }
            ],
            prioridadeOpcoes: [
                {
                    value: 'BAIXA',
                    label: 'Baixa'
                },
                {
                    label: 'Moderada',
                    value: 'MODERADA'
                },
                {
                    label: 'Alta',
                    value: 'ALTA'
                },
                {
                    label: 'Imediata',
                    value: 'IMEDIATA'
                }
            ],
            resultados: [
                { value: 'EFICAZ', label: 'Eficaz' },
                { value: 'NAO_EFICAZ', label: 'Não eficaz' }
            ],
            filters: {
                search: '',
                status: '',
                customer: '',
                branch: '',
                dateType: 'INICIAL',
                period: '',
                involvedType: 'USUARIO',
                involved: '',
                riskGroup: '',
                riskFactor: '',
                riskDescription: '',
                objective: '',
                priority: '',
                epi: '',
                epc: '',
                result: ''
            },
            epis: [],
            epcs: [],
            riscos: [],
            descRiscos: [],
            customers: [],
            branches: [],
            usuarios: [],
            funcionarios: [],
            status: [
                {
                    label: 'Pendente',
                    value: 'PENDENTE'
                },
                {
                    label: 'Em andamento',
                    value: 'EM_ANDAMENTO'
                },
                {
                    label: 'Concluído',
                    value: 'CONCLUIDO'
                },
                {
                    label: 'Excluído',
                    value: 'EXCLUIDO'
                }
            ],
            perPage: 10,
            total: 0,
            currentPage: 1,
            currentPerPage: null,
            submitted: false,
        };
    },

    async mounted() {
        this.selectedCompany = store.getCurrentCompanyObject();
        this.selectedCustomer = store.getCurrentCustomersObjects();

        this.$serviceCausa = new PlanoAcaoCausaService();

        await this.getCustomers();
        await this.getPlanosDeAcao();
        await this.getUsuarios();
        await this.getRisks();
        await this.getDescRisco();
        await this.getEpis();
        await this.getEpcs();
    },

    methods: {
        async onPage(event) {
            this.currentPage = event.page + 1;
            this.perPage = event.rows;

            this.updateDisplayedData();
        },
        async getRisks() {
            this.riscos = await this.$serviceCausa.getRisks();
        },
        async getDescRisco() {
            this.descRiscos = await this.$serviceCausa.getDescRisco();
        },
        toggleMenu(event, data) {
            this.selectedPlanAction = data;
            this.$refs.menu.toggle(event, data);
        },
        async removerFiltros() {
            const response = await this.$service.get(this.selectedCompany.id, '');

            this.list = response.data;

            this.filtered = false;
            this.filters = {
                search: '',
                status: '',
                customer: '',
                branch: '',
                dateType: 'INICIAL',
                date: '',
                involvedType: 'USUARIO',
                involved: '',
                riskGroup: '',
                riskFactor: '',
                riskDescription: '',
                objective: '',
                priority: '',
                epi: '',
                epc: '',
                result: ''
            };
        },
        async getUsuarios() {
            const { data } = await new BaseService('/users/combo').findAll({});
            this.usuarios = data;
        },
        async getFuncionarios() {
            const { data } = await new BaseService('customer/employees/by-customer').findAll({ customerId: this.planoAcao.customerId });
            this.funcionarios = data;
        },
        async getEpis() {
            const { data } = await new BaseService(`/epi`).findAll({ limit: 100000 });
            this.epis = data.items;
        },
        async getEpcs() {
            const { data } = await new BaseService(`/epc`).findAll({ limit: 100000 });
            this.epcs = data.items;
        },
        goToTimeLime() {
            this.showTimeLine = true;
        },
        async getPlanosDeAcao() {
            this.$service = new PlanoAcaoService();

            const response = await this.$service.get(this.selectedCompany.id, '');

            this.total = response.data.length;
            this.totalPage = Math.ceil(response.data.length / this.perPage);

            this.list = response.data;
            this.updateDisplayedData();

            if (this.selectedCustomer.length) {
                this.list = this.list.filter((element) => {
                    return element.customerId === this.selectedCustomer[0].id;
                });
            }
        },
        updateDisplayedData() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            this.paginatedList = this.list.slice(startIndex, endIndex);
        },
        changePage(newPage) {
            if (newPage >= 1 && newPage <= this.totalPages) {
                this.currentPage = newPage;
                this.updateDisplayedData();
            }
        },
        nextPage() {
            this.changePage(this.currentPage + 1);
        },
        prevPage() {
            this.changePage(this.currentPage - 1);
        },
        async removePlanosDeAcao() {
            this.loadingRemove = true;
            this.submitted = false ;
            if(!this.removeJustify){
                 this.submitted = true
                 this.loadingRemove = false;
                 return;
            }
            const { id } = this.selectedPlanAction;
            if (id) {
                this.$service = new PlanoAcaoService();
                await this.$service.delete(id, this.removeJustify);
                await this.getPlanosDeAcao();
                await this.getCustomers();
                this.showRemovePlan = false;
                this.loadingRemove = false;
                this.showJustifyRemovePlan = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Plano de ação removido com sucesso',
                    life: 3000
                });
            } else {
                this.showRemovePlan = true;
                this.loadingRemove = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao remover Plano de ação contate o suporte',
                    life: 3000
                });
            }
        },

        handleFormatDate(date) {
            return `${moment(date).format('DD/MM/YYYY')} às ${moment(date).format('HH:mm')}h`;
        },
        handleDate(date) {
            return `${moment(date).format('DD/MM/YYYY')}`;
        },
        async getCustomers() {
            const { data } = await new BaseService('/customers/combo').findAll({});
            this.customers = data;
        },
        async getCustomerBranches() {
            const { data } = await new BaseService(`/customer/branches/combo-by-customer/${this.filters.customer}`).findAll({});
            this.branches = data;
        },
        filterList() {
            let aux = [...this.list];
            console.log(this.filters)
            if (this.filters.search) {
                aux = this.filterBySearch(aux);
            }

            if (this.filters.status) {
                aux = this.filterByStatus(aux);
            }

            if (this.filters.customer) {
                aux = this.filterByCustomer(aux);
            }

            if (this.filters.branch) {
                aux = this.filterByBranch(aux);
            }

            if (this.filters.date) {
                aux = this.filterByDate(aux);
            }

            if (this.filters.involved) {
                aux = this.filterByPerson(aux);
            }

            if (this.filters.riskGroup) {
                aux = this.filterByRiskGroup(aux);
            }

            if (this.filters.riskFactor) {
                aux = this.filterByRiskFactor(aux);
            }

            if (this.filters.riskDescription) {
                aux = this.filterByRiskDescription(aux);
            }

            if (this.filters.objective) {
                aux = this.filterByObjective(aux);
            }

            if (this.filters.priority) {
                aux = this.filterByPriority(aux);
            }

            if (this.filters.epi) {
                aux = this.filterByEpi(aux);
            }

            if (this.filters.epc) {
                aux = this.filterByEpc(aux);
            }

            if (this.filters.result) {
                aux = this.filterByResult(aux);
            }

            this.list = aux;
        },
        filterBySearch(list) {
            return list.filter((element) => element.nomeAcao.toLowerCase().includes(this.filters.search.toLowerCase()));
        },
        filterByStatus(list) {
            return list.filter((element) => element.status === this.filters.status);
        },
        filterByCustomer(list) {
            return list.filter((element) => element.customerId === this.filters.customer);
        },
        filterByBranch(list) {
            return list.filter((element) => element.unidade.id === this.filters.branch);
        },
        filterByDate(list) {
            if (this.filters.dateType === 'INICIAL') {
                return list.filter((element) => element.dataInicial >= this.filters.date[0] && element.dataInicial <= this.filters.date[1]);
            }
            if (this.filters.dateType === 'PREVISTA') {
                return list.filter((element) => element.dataPrevisao >= this.filters.date[0] && element.dataInicial <= this.filters.date[1]);
            }
            if (this.filters.dateType === 'CONCLUSAO') {
                return list.filter((element) => element.dataConclusao >= this.filters.date[0] && element.dataInicial <= this.filters.date[1]);
            }
        },
        filterByPerson(list) {
            return list.filter((element) => element.envolvidos.map((el) => el.nomeEnvolvido).includes(this.filters.involved));
        },
        filterByRiskGroup(list) {
            return list.filter((element) => element.causas.map((el) => el.grupoRisco).includes(this.filters.riskGroup));
        },
        filterByRiskFactor(list) {
            return list.filter((element) => element.causas.map((el) => el.riscoId).includes(this.filters.riskFactor));
        },
        filterByRiskDescription(list) {
            return list.filter((element) => element.causas.map((el) => el.riscoDescRiscoId).includes(this.filters.riskDescription));
        },
        filterByObjective(list) {
            return list.filter((element) => element.objetivoAcao === this.filters.objective);
        },
        filterByPriority(list) {
            return list.filter((element) => element.prioridadeCausa === this.filters.priority);
        },
        filterByEpi(list) {
            return list.filter((element) => element.epiId === this.filters.epi);
        },
        filterByEpc(list) {
            return list.filter((element) => element.epcId === this.filters.epc);
        },
        filterByResult(list) {
            return list.filter((element) => element.resultado === this.filters.result);
        },
        goToEdit() {
            const { id } = this.selectedPlanAction;
            this.$router.push(`${id}?edit=true`);
        },
        handleKeyUp(event) {
            if (event.key === 'Enter' || event.code === 'NumpadEnter') {
                this.filtered = true;
            }
        }
    },

    watch: {
        'filters.customer': async function () {
            this.filters.branch = '';

            if (this.filters.customer) {
                await this.getCustomerBranches();
            }
        },
        list: function () {
            this.updateDisplayedData();
        }
    },
    computed: {
        removeFilters(){
            return this.filters.status || this.filters.search || this.filters.customer ||  this.filters.branch ? true : false
        }
    }
};
</script>

<style scoped>
.breadcrumb-container {
    color: #495057;
}
.pi-home,
.pi-angle-right {
    font-size: 2rem;
}

.dropdown {
    border-radius: 6px;
}

.PENDENTE {
    background-color: #f7ffc6;
    color: #504d05;
}

.EM_ANDAMENTO {
    background-color: #c3f3f6;
    color: #0d0540;
}

.CONCLUIDO {
    background-color: #c3f6d9;
    color: #0e4005;
}

.EXCLUIDO {
    background-color: #ffd3d3;
    color: #700d06;
}
.BAIXA {
    background-color: #c3f6d9;
    color: #0e4005;
}
.MODERADA {
    background-color: #f7ffc6;
    color: #504d05;
}
.ALTA {
    background-color: #ffaa55;
    color: #7e2a04;
}
.IMEDIATA {
    background-color: #ffd3d3;
    color: #700d06;
}
.remove-dialog > h3 {
    font-size: 24px;
}
.remove-dialog > p {
    font-size: 22px;
}
.p-button-remove {
    background-color: red;
}
</style>
