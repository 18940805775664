import { getCompany } from './auth';
import { getClientBase } from './http';

export default class PlanoAcaoCausaService {
    constructor() {
        this.xApiKey = getCompany();
    }
    async post(body) {
        return getClientBase().post('plano-acao-fator-risco', body, { headers: { 'Content-Type': 'application/json' } });
    }
    async patch(id = '', body) {
        if (!id) throw new Error('Id inválido');
        return getClientBase().patch(`plano-acao-fator-risco/${id}`, body, { headers: { 'Content-Type': 'application/json' } });
    }
    async delete(id = '') {
        if (!id) throw new Error('Id inválido');
        return getClientBase().delete(`plano-acao-fator-risco/${id}`);
    }
    async getCausaByPlan(id) {
        return (await getClientBase().get(`plano-acao-fator-risco/combo-plano-acao/${id}`)).data;
    }
    async getRisks() {
        return (await getClientBase().get('plano-acao-fator-risco/riscos')).data;
    }
    async getRiskById(id = '') {
        if (!id) throw new Error('Id do risco não encontrado');
        return (await getClientBase().get(`plano-acao-fator-risco/riscos/${id}`)).data;
    }
    async getMatrizes() {
        return (await getClientBase().get('matriz-config')).data.items;
    }
    async getColunas(id) {
        return (await getClientBase().get(`plano-acao-fator-risco/matriz-colunas/${id}`)).data;
    }
    async getLinhas(id) {
        return (await getClientBase().get(`plano-acao-fator-risco/matriz-linhas/${id}`)).data;
    }
    async getResultado(eixoX, eixoY) {
        return (await getClientBase().get(`plano-acao-fator-risco/matriz-result/${eixoX}/${eixoY}`)).data[0];
    }
    async getMatrizesById(id = '') {
        if (!id) return;
        const { data } = await getClientBase().get(`matriz-config/${id}`);
        return Object.assign({}, data);
    }
    async getDescRisco() {
        return (await getClientBase().get('plano-acao-fator-risco/desc-risco')).data;
    }
    async getGrupoCores() {
        return (await getClientBase().get('matriz-config/grupo-cores')).data;
    }
}
